import CardsContainer from "./components/CardsContainer";

function App() {
  return (
    <div className="App">
      <CardsContainer/>
    </div>
  );
}

export default App;
