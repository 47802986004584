import '../css/cards_container.css'
import Card from './Card';

import {useState, useEffect} from 'react'
import restApiHost from '..';


function CardsContainer () {

    const [cards, setCards] = useState(null);
    const [country, setCountry] = useState(null);

    useEffect (() => {

        async function fetchCards () {
            const response = await fetch(restApiHost + '/api/get_games/' + country + '/');
            const data = await response.json();
            const cards = data.data;
            setCards(cards);

        };

        async function fetchCountryName() {
            const response = await fetch('https://zaymi-online24.store/geo/get_country_name/');
            const data = await response.json();
            const country = data.country_name.toLowerCase();
            setCountry(country);
        }

        if (country === null){
            fetchCountryName();
        }
        if (cards == null && country !== null) {
            fetchCards();
        }


    }, [cards, country])


    return (
        <div className="cards-container">
        {cards !== null && (<div className='cards-cards'>
            {cards.map((item) => (<Card key = {item.id} image = {item.image} title = {item.title} promocode = {item.promocode} free_spins = {item.free_spins} link = {item.link} percent={item.percent} /> ))}
            </div>)}
        </div>
    )
};

export default CardsContainer;